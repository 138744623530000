/* tslint:disable:max-func-body-length */

import { Waiting } from '@msdyn365-commerce-modules/utilities';
import * as Msdyn365 from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import * as React from 'react';
import { IDobbiesAddProductToCartViewProps } from '../dobbies-add-product-to-cart';

import { getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';

export enum TierType {
    Club = 'club',
    ClubPlus = 'club-plus',
    ClubPremium = 'club-premium',
    ClubPlusFreeTrial = 'club-plus-trial'
}

export interface IDobbiesProductAddToCartProps {
    props: IDobbiesAddProductToCartViewProps;
}

const DobbiesProductAddToCart: React.FC<IDobbiesProductAddToCartProps> = props => {
    const [itemAdded, setItemAdded] = React.useState(false);
    const { isAuthenticated } = props.props.context.request.user;
    const { authenticationMessage, loadingDobbiesExperienceMessage } = props.props.resources;

    const { clubPremiumTierId, clubPlusTierId, clubPlusTrialTierId } = props.props.config;

    React.useEffect(() => {
        const addProductToCart = async () => {
            // get product from data that we want to add into cart.

            // VSI CUSTOMIZATION - START
            const context = props.props.context.actionContext;
            // props.props.context.request.channel!.RecordId

            // Clear cart on page load
            const cartState = await getCartState(props.props.context.actionContext);

            const lineItemIdsToRemove = [];
            let productToAdd;

            const activeCart = cartState.cart;
            for (const activeCartLine of activeCart.CartLines || []) {
                lineItemIdsToRemove.push(activeCartLine.LineId!);
            }

            if (lineItemIdsToRemove.length > 0) {
                try {
                    await cartState.removeCartLines({ cartLineIds: lineItemIdsToRemove });
                } catch (error) {
                    throw error;
                }
            }

            if (!props.props.context.request.query?.tier) {
                return;
            }

            const tier = props.props.context.request.query.tier.toLowerCase();

            if (tier === TierType.ClubPremium) {
                const variantInput = new SelectedVariantInput(clubPremiumTierId!, props.props.context.request.channel!.RecordId);
                const newProduct = await getSelectedVariant(variantInput, context);

                productToAdd = newProduct;
            } else if (tier === TierType.ClubPlusFreeTrial) {
                const variantInput = new SelectedVariantInput(clubPlusTrialTierId!, props.props.context.request.channel!.RecordId);
                const newProduct = await getSelectedVariant(variantInput, context);

                productToAdd = newProduct;
            } else {
                const variantInput = new SelectedVariantInput(clubPlusTierId!, props.props.context.request.channel!.RecordId);
                const newProduct = await getSelectedVariant(variantInput, context);

                productToAdd = newProduct;
            }

            // if cartLines is empty add product to cart
            if (cartState.cart.CartLines && cartState.cart.CartLines.length === 0) {
                await cartState.addProductToCart({ product: productToAdd!, count: 1, enableStockCheck: false });
                // After adding product to cart redirects user to checkout page
                setItemAdded(true);
            }
            const checkoutLink = Msdyn365.getUrlSync('checkout', props.props.context.actionContext) || '';

            if (tier === TierType.ClubPremium) {
                window.location.assign(`${checkoutLink}?tier=club-premium`);
            } else if (tier === TierType.ClubPlus) {
                window.location.assign(`${checkoutLink}?tier=club-plus`);
            } else {
                window.location.assign(`${checkoutLink}`);
            }
        };

        const loyaltyGroups = props.props.data.loyaltyCard.result?.LoyaltyGroups;
        const tier =
            loyaltyGroups &&
            loyaltyGroups.length > 0 &&
            loyaltyGroups[0].LoyaltyCardTiers &&
            loyaltyGroups[0].LoyaltyCardTiers.length > 0 &&
            loyaltyGroups[0].LoyaltyCardTiers[0].TierId;
        // If user is authenticated
        if (isAuthenticated) {
            // if user tierId is not 'club plus' add product to cart and redirect to checkout
            if (!props.props.data.loyaltyCard.result?.LoyaltyGroups) {
                // redirect user to loyalty page
                const loyaltyJoinLink = props.props.context?.app?.config?.loyaltyJoinLink;
                window.location.assign(loyaltyJoinLink);
            } else {
                // check if loyalty tier is club free then proceed
                if (tier && tier.toLowerCase() !== props.props.config.clubPlusText?.toLowerCase()) {
                    !itemAdded && addProductToCart();
                    // TODO: if tier is expired what to do
                } else if (
                    tier &&
                    tier.toLowerCase() === props.props.config.clubPlusText?.toLowerCase() &&
                    loyaltyGroups &&
                    loyaltyGroups.length &&
                    loyaltyGroups[0].LoyaltyCardTiers &&
                    loyaltyGroups[0].LoyaltyCardTiers.length
                ) {
                    const validToDate = loyaltyGroups && loyaltyGroups[0].LoyaltyCardTiers[0].ValidTo;
                    const endDate = new Date(validToDate ?? '');
                    const today = new Date();
                    const timeDifference = endDate.getTime() - today.getTime() + 24 * 60 * 60 * 1000;
                    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                    const daysLimit = props.props.context?.app?.config?.clubPlusRenewalDays || 30;
                    if (remainingDays <= daysLimit) {
                        !itemAdded && addProductToCart();
                    } else {
                        const loyaltyLink = props.props.context?.app?.config?.loyaltyLink;
                        window.location.assign(loyaltyLink);
                    }
                } else {
                    // redirects user to loyalty page (so he can view his points)
                    const loyaltyLink = props.props.context?.app?.config?.loyaltyLink;
                    window.location.assign(loyaltyLink);
                }
            }
        } else {
            // if user is not authenticated redirects to checkout
            const signinLink = props.props.context.request.user.signInUrl;
            setItemAdded(true);
            window.location.assign(signinLink!);
        }
    }, [itemAdded]);
    // If user is not authenticated show him/her message with loader and redirects him to signin page
    if (isAuthenticated) {
        return (
            <div className='loading-message-container'>
                <div className='loading-message-container__innerbox'>
                    <Waiting />
                    <span>{authenticationMessage}</span>
                </div>
            </div>
        );
        // If user is authenticated display him/her custom message please wait while we're loading club experience
    } else {
        return (
            <div className='loading-message-container'>
                <div className='loading-message-container__innerbox'>
                    <Waiting />
                    <span>{loadingDobbiesExperienceMessage}</span>
                </div>
            </div>
        );
    }
};

export default DobbiesProductAddToCart;
